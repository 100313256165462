import React from 'react';
import StoryblokComponents from '../storyblok/';

class StoryblokEntry extends React.Component {
	static getDerivedStateFromProps(props, state) {
		if (state.story.uuid === props.pageContext.story.uuid) {
			return null;
		}

		return StoryblokEntry.prepareStory(props);
	}

	static prepareStory(props) {
		const story = Object.assign({}, props.pageContext.story);
		story.content = JSON.parse(story.content);

		return { story };
	}

	constructor(props) {
		super(props);

		this.state = StoryblokEntry.prepareStory(props);
	}

	render() {
		let content = this.state.story.content;

		return (
			<div>
				{React.createElement(StoryblokComponents(content.component), {
					key: content._uid,
					blok: content,
					currentSlug: this.state.story.slug,
				})}
			</div>
		);
	}
}

export default StoryblokEntry;
